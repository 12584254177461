var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-10", attrs: { justify: "center" } },
    [
      _c("v-col", { staticClass: "text-right", attrs: { cols: "10" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex", "justify-content": "flex-end" } },
          [
            _c("approve-button", {
              attrs: { duct: _vm.duct, aids: _vm.selectedIds },
              on: {
                success: function($event) {
                  return _vm.clearSelection()
                }
              }
            }),
            _c("reject-button", {
              attrs: { duct: _vm.duct, aids: _vm.selectedIds },
              on: {
                success: function($event) {
                  return _vm.clearSelection()
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              dense: "",
              "sort-desc": "",
              "show-select": "",
              headers: _vm.headers,
              items: _vm.assignments,
              "item-key": "AssignmentId",
              loading: _vm.loading,
              "sort-by": "Timestamp",
              "items-per-page": 100,
              "footer-props": { itemsPerPageOptions: [10, 30, 50, 100, 300] },
              search: _vm.searchQuery
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c(
                      "v-card-title",
                      [
                        _vm._v(" Assignments "),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.listAssignments(false)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-refresh")])],
                          1
                        ),
                        _c("v-spacer"),
                        _c("v-select", {
                          attrs: {
                            "hide-details": "",
                            loading: _vm.loadingHITTypes,
                            items: Object.keys(_vm.HITIds),
                            label: "HITTypeId"
                          },
                          model: {
                            value: _vm.HITTypeId,
                            callback: function($$v) {
                              _vm.HITTypeId = $$v
                            },
                            expression: "HITTypeId"
                          }
                        }),
                        _c("v-spacer"),
                        _c("v-text-field", {
                          attrs: {
                            "single-line": "",
                            "hide-details": "",
                            "append-icon": "mdi-magnify",
                            label: "Search"
                          },
                          model: {
                            value: _vm.searchQuery,
                            callback: function($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "item.WorkerId",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.PlatformWorkerId + "\n(" + item.WorkerId + ")"
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.AssignmentId",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "text-truncate",
                        staticStyle: { "max-width": "100px" }
                      },
                      [_vm._v(" " + _vm._s(item.AssignmentId) + " ")]
                    )
                  ]
                }
              },
              {
                key: "item.AcceptTime",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.stringifyUnixTime(item.AcceptTime * 1000)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.SubmitTime",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.stringifyUnixTime(item.SubmitTime * 1000)) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item.AutoApprovalTime",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.stringifyUnixTime(item.AutoApprovalTime * 1000)
                        ) +
                        " "
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }