var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mx-2",
                          attrs: { dark: "", color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.dialog.show()
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-check-bold")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Approve assignment(s)")])]
      ),
      _c("tutti-dialog", {
        ref: "dialog",
        attrs: {
          persistent: "",
          title: "Approve Assignment(s)",
          maxWidth: "500",
          actions: [
            {
              label: "Confirm",
              color: "indigo darken-1",
              text: true,
              onclick: _vm.approveAssignments
            },
            {
              label: "Cancel",
              color: "grey darken-1",
              text: true
            }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(
                  " Approve " + _vm._s(_vm.aids.length) + " assignments? "
                ),
                _c("v-text-field", {
                  attrs: { label: "Message to workers (optional)" },
                  model: {
                    value: _vm.message,
                    callback: function($$v) {
                      _vm.message = $$v
                    },
                    expression: "message"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("tutti-snackbar", { ref: "snackbar" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }